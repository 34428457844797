<template>
	<v-row>
		<BottomNavigationContainer v-if="menu.length > 1" :menu="menu" />

		<v-col cols="12" class="d-none d-md-block">
			<ToolbarContainer :menu="menu" />
			<v-toolbar elevation="1" height="25" rounded="b">
				<Breadcrumbs />
			</v-toolbar>
		</v-col>

		<v-col cols="12" lg="12">
			<router-view />
		</v-col>
	</v-row>
</template>

<script>
import Breadcrumbs from "../Widgets/Breadcrumbs";
import ToolbarContainer from "../Widgets/ToolbarContainer";
import BottomNavigationContainer from "../Widgets/BottomNavigationContainer";
import {mapState} from "vuex";

export default {
	name: "ClienteContainer",
	components: { Breadcrumbs, ToolbarContainer, BottomNavigationContainer },
    props: ["idcliente"],
	data: () => ({
		menu: [],
	}),
	methods: {
		montarMenu() {
			let m = [];

			if (this.permissao("cliente_listar")) {
				m.push({
					titulo: "Clientes",
					titulo_app: "Clientes",
					icon: "mdi-text-box-outline",
					to: "/cliente",
					tooltip: "Lista de clientes da empresa",
				});
			}

            if (this.permissao("cliente_cadastrar")  && this.idcliente != undefined ) {
                m.push({
                    titulo: "Dados Principais",
                    titulo_app: "Dados Principais",
                    icon: "mdi-account-details",
                    to: `/cliente/get/${this.idcliente}`,
                    tooltip: "Tela inicial do cliente",
                });
            }

			if (this.permissao("cliente_cadastrar") && this.idcliente == undefined) {
				m.push({
					titulo: "Novo Cliente",
					titulo_app: "Cadastro",
					icon: "mdi-text-box-plus-outline",
					to: "/cliente/novo",
					tooltip: "Tela de cadastro de cliente",
				});
			}

			this.menu = m;
		},
	},
	mounted() {
		this.montarMenu();
	},
    computed: {
        ...mapState(["backendUrl"]),
    },
    watch:{
        "idcliente": function () {
            this.montarMenu()
        }
    }
};
</script>

<style scoped>
</style>
